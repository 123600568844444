import { Card, CardContent, Grid } from '@material-ui/core';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import React from 'react'
import ANN from './ANN';
import CNN from './CNN';



const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const Tab = styled(TabUnstyled)`
    font-family: IBM Plex Sans, sans-serif;
    color: white;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: ${blue[400]};
    }
  
    &:focus {
      color: #fff;
      border-radius: 3px;
      outline: 2px solid ${blue[200]};
      outline-offset: 2px;
    }
  
    &.${tabUnstyledClasses.selected} {
      background-color: ${blue[50]};
      color: ${blue[600]};
    }
  
    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `;

const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    background-color: ${blue[500]};
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `;


export default function ML() {

  return (
    <div style={{ marginTop: "80px", marginLeft: "auto", marginRight: "auto", maxWidth: "1400px" }}>
      <div >
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} xl={12}>
                <TabsUnstyled defaultValue={1}>
                  <TabsList>
                    <Tab>Cyanobacteria Abundance (ANN)</Tab>
                    <Tab>Plankton Imaging (CNN)</Tab>
                  </TabsList>
                  <TabPanel value={0}>{<ANN />}</TabPanel>
                  <TabPanel value={1}>{<CNN />}</TabPanel>
                </TabsUnstyled>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
