import { Box, Button, FormHelperText, Grid, MenuItem, Typography } from '@material-ui/core';
import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { brightFrostyGlass } from '../../style/textures';
import bkg from '../../assets/others/plankton2.jpg';

import axios from 'axios';
import { MdPhotoCamera } from 'react-icons/md';
import Pie from '../../pages/ml/Pie';
import { Skeleton } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';




const useStyles = makeStyles((theme) => ({
    blureButton: {
        ...brightFrostyGlass,
        backdropFilter: 'blur(5px)',
        width: "120px", 
        height: "40px", 
        border: "2px solid",
        textTransform: 'none',
    },
    gridBackground: {
        backgroundImage: `url(${bkg})`, 
        objectFit: "cover", 
        backgroundRepeat: "no-repeat", 
        backgroundPosition: "left center",
    },
    body: {
        ...brightFrostyGlass,
        padding: "20px 20px",
        overflow: "hidden",
    },
    select: {
        width: "220px"
    }

}));


const createFileFromUrl = async (url, filename) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: blob.type });
  };


function CNNInference({ }) {
    const classes = useStyles()
    const [file, setFile] = useState()
    const [inference, setInference] = useState()
    const [loading, setLoading] = useState(false)
    const [channels, setChannels] = useState(3)
    const [modelIndex, setModelIndex] = useState(1)


    const formRef = useRef(null);

    useEffect(() => {
        const loadFile = async () => {
          const imageFile = await createFileFromUrl("/Ceratium01.jpeg", "Ceratium01.jpeg");
          setFile(imageFile);
        };
    
        loadFile().then(() => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));
          }
        }
        )

      }, []);
      
      
    function handleChange(event) {
      setFile(event.target.files[0]);
      setInference(null);
      setLoading(false);
    }

    function handleSubmit(event) {
        event.preventDefault()
        const url = `https://cmapdatavalidation.com/cnn?model_index=${modelIndex}&channels=${channels}`;
        if (!file) {
            alert('Select an image file first.')
            return
        }
        setInference(null);
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
        };
        axios.post(url, formData, config).then((response) => {
            setInference(response.data);
            setLoading(false);
        });
    
      }    

    const handleModelChange = (event: SelectChangeEvent) => {
        setModelIndex(event.target.value);
    };
    const handleChannelsChange = (event: SelectChangeEvent) => {
        setChannels(event.target.value);
    };

    return (          
        <div className={classes.gridBackground}>
            <form ref={formRef} onSubmit={handleSubmit} >
                <Grid container spacing={2} className={classes.body}>

                <Grid container direction="row" justifyContent="flex-start" alignItems="center" >

                    <Grid item md={3} xs={12} >
                        <FormHelperText>Imaging Device</FormHelperText>
                        <Select value={modelIndex} onChange={handleModelChange} className={classes.select} labelId="model-select-label-id" id="model-select-id">
                            <MenuItem value={0}>All</MenuItem>
                            <MenuItem value={1}>IFCB</MenuItem>
                            <MenuItem value={2}>ZOOSCAN</MenuItem>
                            <MenuItem value={3}>ISIIS</MenuItem>
                            <MenuItem value={4}>ZOOVIS</MenuItem>
                            {/* <MenuItem value={5}>All Imbalanced</MenuItem> */}
                            {/* <MenuItem value={6}>IFCB Imbalanced</MenuItem> */}
                            {/* <MenuItem value={6}>All-SYKE-ISIIS</MenuItem> */}
                        </Select>                        
                    </Grid>

                    <Grid item md={3} xs={12} >
                        <FormHelperText>Channels</FormHelperText>
                        <Select value={channels} onChange={handleChannelsChange} className={classes.select} labelId="channels-select-label-id" id="channels-select-id">
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                        </Select>
                    </Grid>

                    </Grid>


                    <Grid item md={6} sm={12} >
                        <Grid container spacing={2} style={{height:"100%"}}>
                            <Grid item md={12} style={{height:"90%"}}>
                                {file ? <img src={URL.createObjectURL(file)} style={{height:'400px', width:'100%', objectFit:'contain'}}/> 
                                : <div style={{height:'400px', width:'100%'}} />
                                }
                            </Grid>
                            <Grid item md={12} sm={12}>
                                <Button variant="outlined" component="label" endIcon={<MdPhotoCamera />} className={classes.blureButton}>                                     
                                    Image 
                                    <input hidden accept="image/*" type="file" onChange={handleChange}/>
                                </Button> 
                                <Button variant="outlined" 
                                        disabled={file == null} 
                                        type="submit" className={classes.blureButton} style={{marginLeft: "10px"}}> 
                                    Classify 
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item md={6} sm={12} >
                        {
                        inference ? 
                                    <Grid container spacing={2} >
                                        <Grid item md={12} sm={12}>
                                            <Box display="flex" justifyContent="center">
                                                <Typography variant="body1" style={{marginBottom:"20px"}}>
                                                    Inference: {inference.prediction} (p: {inference.prediction_probability.toFixed(2)})
                                                </Typography>        
                                            </Box >
                                        </Grid>
                                        <Grid item md={12} >
                                            <Pie probs={inference.all_probs} class_names={inference.all_prob_names} />
                                        </Grid>
                                    </Grid>  
                                :     
                                ( loading ? (<>
                                                <Skeleton variant="rectangular" animation="wave" style={{height:'30%', width:'100%'}} /> 
                                                <Skeleton variant="rectangular" animation="wave" style={{height:'70%', width:'100%'}} />   
                                            </>    
                                            ) : null )                        
                        }
                    </Grid>

                </Grid> 
            </form>
         </div> 
    )

}



export default CNNInference;
